<template>
  <div style="background-color: #f4f5f7;">
    <div :class="'mobileNavBar ' + deviceType">

      <div class="appBar-header">
        <div @click="routeBack('/', false)" class="header-route-back">
            <span v-if="deviceType === 'ios'">
              <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
            </span>
          <span v-else>
              <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
            </span>
        </div>
        <div class="header-title-container">
          <p class="header-title">
            Anket
          </p>
        </div>
      </div>
    </div>
    <div v-if="!pageLoaded" class="partial-loader">
      <loader />
    </div>
    <div v-else class="survey-container">
      <template v-if="activeState === 'form'">
        <img :src="survey.info.headerImageUrl" class="header-image">
        <div class="survey-title">{{ survey.title }}</div>
        <div class="survey-description">{{ survey.description }}</div>
        <div  class="main-area" style="padding: 0 10px 20px; width: 84%">
          <single-gig :item="survey.gig" :show-add-to-list="false" class="gig-list-gig" style="pointer-events: none; margin: 10px auto" />
          <div class="mt-3">
            <div v-for="question in survey.questions" :key="question.id" style="margin-top: 20px">
              <div style="font-size: 12px">{{ question.title }} <span v-if="question.isRequired" style="color: red">*</span></div>
              <div v-if="question.answerType === 'radio_numeric'" style="display: flex">
                <button class="type-radio-body" v-for="(option, index) in 10" :key="index" @click="question.score = option">
                  <p class="type-radio-text" style="font-size: 10px" :style="question.score === option ? 'font-weight: 500;' : ''">{{option}}</p>
                  <div class="type-radio-body-button" :style="question.score === option ? 'background-color: #00a575; border: none;' : ''">
                    <span class="selected-radio-mask" v-if="question.score === option"></span>
                  </div>
                </button>
              </div>
              <div v-if="question.answerType === 'free_input'" class="mt-1">
                <custom-input label=""
                              inputType="textArea"
                              v-model="question.freeInput"
                              :valid="null"
                              maxlength="300"
                              style="width: 100%; height: 100px;"
                />
              </div>
            </div>
          </div>
          <custom-button :button-type="'green'" :disabled="buttonDisabled" class="mt-3" style="width: 100%" @click="submit">Tamamla</custom-button>
        </div>
      </template>
      <template v-else>
        <div style="padding-top: 40px">
          <img :src="survey.info.submitImageUrl" class="submit-image">
          <div class="ml-2" style="color: #2d3640; font-size: 20px; font-weight: 500; width: 90%; line-height: 32px; margin: auto; padding-bottom: 20px">
            Bionluk’taki tüm deneyimlerin bizim için çok önemli! Tüm bildirimlerine sarıldık 🤗 Önce gönüller bir, yeni geliştirmeler de yolda olsun.  🚀
          </div>
          <div style="display: flex; justify-content: center; margin-top: 40px; margin-bottom: 20px;">
            <router-link to="/">
              <custom-button :button-type="'green'"  style="width:220px;">Anasayfa'ya Dön</custom-button>
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import CustomButton from "@/pages/components/CustomButton"
import Api from "@/utils/request"
import CustomInput from "@/pages/components/CustomInput"
import SingleGig from "@/pages/components/singleGig"

export default {
  name: "src-pages-body-survey-v1",
  components:{ SingleGig, CustomInput, CustomButton },
  data() {
    return {
      pageLoaded: false,
      buttonDisabled: false,
      survey: {
        title: null,
        description: null,
        questions: [],
        info: {},
        gig: null
      },
      activeState: 'form',
    }
  },

  methods: {
    submit() {
      this.buttonDisabled = true
      let uuid = this.$store.state.routerParams[0]
      Api.post(`/users/surveys/${uuid}`, this.survey.questions)
        .then(() => {
          this.$toasted.global.infoToast({description: "İşlem tamam!"})
          this.activeState = 'submit'
        })
        .catch(err => {
          if (err.status === 404 || err.status === 403  || err.status === 400) {
            this.$toasted.global.errorToast({ description: err.data.message })
          }
        })
        .finally(() => {
          this.buttonDisabled = false
        })
    },
    getSurvey(){
      this.pageLoaded = false
      let uuid = this.$store.state.routerParams[0]
      Api.get(`/users/surveys/${uuid}`)
        .then(({ data }) => {
          if (data.isCompleted) {
            this.survey.info = data.info
            this.activeState = 'submit'
            return
          }
          this.survey.title = data.title
          this.survey.description = data.description
          this.survey.questions = data.questions.map(question => {
            return {
              ...question,
              score: null,
              freeInput: null
            }
          })
          this.survey.info = data.info
          this.survey.gig = data.gig
          if (data.gig) {
            return this.api.general.getGigLikesV2([data.gig.id], 'null', '',  '')
              .then(({ data: likeResult }) => {
                let foundLike = likeResult.data.likeStatuses[0]
                if(foundLike){
                  data.gig.likeStatus = foundLike.likeStatus
                }
              })
          }
        })
        .catch(async err => {
          if (err.status === 404) {
            this.$toasted.global.errorToast({ description: err.data.message })
            await this.$router.push('/')
          }
        })
        .finally(() => {
          this.pageLoaded = true
        })
    }
  },
  created() {
    this.getSurvey()
    this.EventBus.$emit('closeSurveyModal')
  }
}
</script>

<style scoped lang="scss">

.survey-container {
  padding: 60px 0 20px;
  width: 100%;
  .header-image {
    margin: 0 auto;
    border-radius: 15px;
    width: 90%;
    display: block;
  }
  .submit-image {
    border-radius: 15px;
    width: 90%;
    display: block;
    margin: 30px auto;
  }
  .main-area{
    min-height: 200px;
    border-radius: 5px;
    margin: 30px auto;
    box-shadow: 0 0 6px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    align-items: center;
  }
}

.survey-title {
  color: #2d3640;
  font-size: 24px;
  font-weight: 700;
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}

.survey-description {
  color: #2d3640;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  width: 90%;
  margin: 20px auto 0;
}

.main-img-div{
  width:1193px;

  background-color: #000;
  background-size: contain !important;

  .title{
    color: #fff; font-weight: 500; font-size: 40px;
  }

  .sub-title{
    color: #fff; font-size: 28px; font-weight: 300; margin-top: 10px;
  }
}

.type-radio-body {
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #fff;
  margin-top: 10px;
  &:not(:nth-child(1)) {
    margin-left: 16px;
  }

  &:hover {
    color: #00a575;
    background: none !important;
  }

  &:disabled {
    background: none !important;

    .type-radio-text {
      color: #bfc8d2;
    }

    .type-radio-body-button {
      border-color: #bfc8d2;
    }

  }
}

.type-radio-body-button {
  box-sizing: border-box;
  border: 1px solid #8b95a1;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  position: relative;
}

.selected-radio-mask {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

textarea {
  width: 100%;
  margin-top: 23px;
  border-radius: 2px;
  border: solid 1px rgba(75, 79, 82, 0.2);
  background-color: #eff3f8;
  padding: 20px;
  opacity: 0.5;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #2d3640;
  &:focus{
    opacity: 1;
    background-color: #fff;
  }
}

</style>
